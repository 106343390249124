import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ProductListingPage.module.css';

const SectionProductDetails = props => {
  const { publicData, description, intl } = props;

  if (!publicData) {
    return null;
  }

  const {
    department,
    category,
    publishedProductType,
    publishedProductSubType,
  } = publicData || {};

  const product = `${department} / ${category} / ${publishedProductType}` + (publishedProductSubType ? ` / ${publishedProductSubType}` : '');

  return (
    <div className={css.sectionDetails}>
      <div className={css.sectionHeadingContainer} style={{ marginTop: 16 }}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.productDetailsTitle" />
        </Heading>

      </div>
      <div className={css.productDetailsRoot}>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.colour" />
        </p>
        <p className={css.text}>
          {publicData.color}
        </p>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.composition" />
        </p>
        <p className={css.text}>
          {publicData.composition}
        </p>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.careInstructions" />
        </p>
        <p className={css.text}>
          {publicData.careInstructions}
        </p>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.madeId" />
        </p>
        <p className={css.text}>
          {publicData.madeIn}
        </p>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="EditProductPreviewAndPublishForm.productType" />
        </p>
        <p className={css.text}>
          {product}
        </p>
      </div>

      <p className={css.description}>
        {description}
      </p>
    </div>
  )
};

export default SectionProductDetails;
