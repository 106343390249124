import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ProductListingPage.module.css';

const SectionDeliveriesAndReturns = props => {
  const { listingBrand, intl } = props;
  if (!listingBrand) {
    return null;
  }

  const publicData = listingBrand.attributes.publicData || {};
  const { brandLocation, delivery, importDutiesInfo, returns } = publicData;

  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading} style={{ display: 'block', marginBottom: 32 }}>
        <FormattedMessage id="ListingPage.deliveryAndReturnsTitle" />
      </Heading>
      <div className={css.deliveryAndReturnsRoot}>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.sendingFrom" />
        </p>
        <p className={css.text}>
          {brandLocation?.city}, {brandLocation?.country}
        </p>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.delivery" />
        </p>
        <p className={css.text}>
          {delivery}
        </p>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.duties" />
        </p>
        <p className={css.text}>
          {importDutiesInfo}
        </p>

        <p className={css.sectionSubHeading}>
          <FormattedMessage id="ListingPage.returns" />
        </p>
        <p className={css.text}>
          {returns}
        </p>

      </div>


    </div>
  )
};

export default SectionDeliveriesAndReturns;
