import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';

import { FormattedMessage } from '../../../util/reactIntl';
import { types as sdkTypes } from '../../../util/sdkLoader';

import ListingCard from './ListingCard/ListingCard';
import {
    Heading,
    NamedLink
} from '../../../components';

import css from './BrandProductsLimitedSection.module.css';
import { createSlug } from '../../../util/urlHelpers';


const BrandProductsLimitedSection = props => {
    const {
        products,
        brandName,
        brandId,
    } = props;

    return (
        <div className={css.root}>
            <div className='col'>
                <p className={css.subTitle}>
                    Discover more from {brandName}
                </p >

                <div className={css.listingsContainer}>
                    {products?.map(l => (
                        <div className={css.cardWrapper}>
                            <ListingCard
                                listing={l}
                                setActiveListing={() => { }}
                                activeListing={() => { }}
                                allowRedirect={true}
                                showPriceInfo={true}
                                brandName={brandName}
                            />
                        </div>
                    ))}
                </div>

                {brandId &&
                    <NamedLink className={css.link}
                        name="SearchProductsPage"
                        to={{ search: '?pub_brandId=' + brandId }}>
                        <FormattedMessage id="BandProductsLimitedSection.viewMore" />
                    </NamedLink>
                }
            </div>
        </div >
    )
};

export default BrandProductsLimitedSection;
