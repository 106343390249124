import React from 'react';
import { string, func, bool } from 'prop-types';

import { useConfiguration } from '../../../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl, useIntl } from '../../../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../../../util/uiHelpers';
import { propTypes } from '../../../../util/types';
import { formatMoney, formatMoneyRoundWithoutDecimals } from '../../../../util/currency';
import { ensureListing } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';
const { Money } = sdkTypes;
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../../../components';

import css from './ListingCard.module.css';
import { createSlug } from '../../../../util/urlHelpers';

// Utility function to get the first image
const getFirstImage = (currentListing, listingType) => {
  let orderedImages = [];

  const productImagesOrder = currentListing.attributes?.publicData?.productImagesOrder || [];
  orderedImages = [...currentListing.images].sort((a, b) => {
    const aIndex = productImagesOrder.indexOf(a.id?.uuid);
    const bIndex = productImagesOrder.indexOf(b.id?.uuid);
    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  return orderedImages.length > 0 ? orderedImages[0] : null;
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const intl = useIntl();

  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
    activeListing,
    allowRedirect,
    listingId,
    showPriceInfo,
    brandName,
  } = props;
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const { finalDisplayRate, listingType, unitType, discountedPrice, discount } = publicData;
  const slug = createSlug(title);

  const firstImage = getFirstImage(currentListing, listingType) || {};

  const {
    aspectWidth = 3,
    aspectHeight = 4,
    variantPrefix = 'product-card',
  } = config.layout.productImage;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const handleClick = () => {
    if (setActiveListing) {
      setActiveListing(currentListing);
    }
  };

  const active = currentListing?.id?.uuid === activeListing?.id?.uuid;

  const price = (finalDisplayRate && finalDisplayRate?.amount) && new Money(finalDisplayRate.amount, config.currency);
  const formattedFinalDisplayRate = price ? formatMoneyRoundWithoutDecimals(intl, price) : null;

  // const discountedPriceMaybe = discountedPrice
  //   ? formatMoney(intl, new Money(discountedPrice.amount, discountedPrice.currency))
  //   : null;
  const discountedPriceMaybe = discountedPrice
    ? formatMoneyRoundWithoutDecimals(intl, new Money(discountedPrice.amount, discountedPrice.currency))
    : null;

  const formattedPrice = currentListing?.attributes?.price ? formatMoneyRoundWithoutDecimals(intl, currentListing.attributes.price) : null;


  return (
    <NamedLink disabled={!allowRedirect} name={"ProductListingPage"} params={{ id, slug }}>
      <div className={css.root} style={active ? { border: 'solid 1px var(--colorGrey400)', borderRadius: 'var(--borderRadiusMedium)' } : null} onClick={handleClick}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
        <div className={css.info}>
          {formattedFinalDisplayRate &&
            <FormattedMessage id="ListingCard.priceToDisplay" values={{ price: formattedFinalDisplayRate }} />
          }
        </div>

        {showPriceInfo &&
          <div className='col'>
            <p className={css.brandTitle}>{brandName}</p>
            <p className={css.title}>{title}</p>
            <div className={css.priceContainer}>
              {discountedPriceMaybe ?
                <>
                  <p className={css.priceDiscounted}>{formattedPrice}</p>
                  <p className={css.discount}>{discount + '%'}</p>
                  <p className={css.price}>{discountedPriceMaybe}</p>
                </>
                :
                <>
                  <p className={css.price}>{formattedPrice}</p>
                </>
              }
            </div>
          </div>
        }
      </div>
    </NamedLink >
  )
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
