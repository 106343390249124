import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  ProductOrderPanel,
  LayoutSingleColumn,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  fetchListingsBrandProducts,
  fetchListingBrand,
} from './ProductListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ProductListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionGallery from './ProductSectionGallery';

import css from './ProductListingPage.module.css';
import SectionProductDetails from './SectionProductDetails.js';
import SectionDeliveriesAndReturns from './SectionDeliveriesAndReturns.js';
import BrandProductsLimitedSection from './BrandProductsSection/BrandProductsLimitedSection.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ProductListingPageCarouselComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onFetchListingBrand,
    showListingBrandError,
    fetchListingBrandInProgress,
    listingBrand,
    onFetchListingsBrandProducts,
    showListingsBrandProductsError,
    fetchListingsBrandProductsInProgress,
    listingsBrandProducts
  } = props;

  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  useEffect(() => {
    if (currentListing.attributes.publicData.brandId) {
      const brandId = new UUID(currentListing.attributes.publicData.brandId);

      if (brandId && !listingsBrandProducts?.length) {
        onFetchListingsBrandProducts(brandId, config, 10);
      }

      if (!listingBrand) {
        onFetchListingBrand(brandId, config)
      }
    }

  }, [currentListing])

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError || showListingBrandError || showListingsBrandProductsError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id || fetchListingBrandInProgress || fetchListingsBrandProductsInProgress) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.root} topbar={topbar}
        footer={
          <FooterContainer />
        }>

        <div className='col full'>
          {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
              showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
            />
          ) : null}
          {currentListing.id ? (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
            />
          ) : null}

          <div className={css.contentWrapperForProductLayout} style={{ marginBottom: 0 }}>
            <div className={css.mainColumnForProductLayout}>
              <SectionGallery
                listing={currentListing}
                variantPrefix={config.layout.productImage.variantPrefix}
              />
              <div className={css.mobileHeading}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              </div>
            </div>
            <div className={css.orderColumnForProductLayout}>
              <ProductOrderPanel
                className={css.productOrderPanel}
                listing={currentListing}
                listingBrand={listingBrand}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                }
                title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                payoutDetailsWarning={payoutDetailsWarning}
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
              />
            </div>
          </div>
          <div className={css.contentWrapperForProductLayout}>
            <div className={css.mainColumnForProductLayout}>
              <SectionProductDetails
                publicData={publicData}
                description={description}
                intl={intl}
              />
              <SectionDeliveriesAndReturns
                listingBrand={listingBrand}
                intl={intl}
              />
            </div>
            <div className={css.orderColumnForProductLayout}>
              {listingBrand ?
                <NamedLink className={css.brandButton} name="BrandListingPage" params={{ id: listingBrand?.id.uuid, slug: createSlug(listingBrand?.attributes.title) }}>
                  <FormattedMessage id="ProductListingPage.viewTheBrand" />
                </NamedLink>
                : null
              }
            </div>
          </div>

          <div className={css.brandListingsContainer}>
            <BrandProductsLimitedSection
              brandId={listingBrand?.id.uuid}
              brandName={listingBrand?.attributes?.title}
              products={listingsBrandProducts?.filter(l => l.id.uuid !== listingId.uuid)?.slice(0, 5)}
              intl={intl} />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};


ProductListingPageCarouselComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedProductListingPageCarousel = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ProductListingPageCarouselComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    showListingBrandError,
    fetchListingBrandInProgress,
    listingBrand,
    showListingsBrandProductsError,
    fetchListingsBrandProductsInProgress,
    listingsBrandProducts,
  } = state.ProductListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    showListingBrandError,
    fetchListingBrandInProgress,
    listingBrand,
    showListingsBrandProductsError,
    fetchListingsBrandProductsInProgress,
    listingsBrandProducts,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchListingsBrandProducts: (brandId, config, limit) => dispatch(fetchListingsBrandProducts(brandId, config, limit)),
  onFetchListingBrand: (brandId, config, limit) => dispatch(fetchListingBrand(brandId, config))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ProductListingPageCarousel = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedProductListingPageCarousel);

export default ProductListingPageCarousel;
