import React from 'react';

import css from './ProductListingPage.module.css';
import { ListingImageGallery } from '../../components';

const SectionGallery = props => {
  const { listing, variantPrefix } = props;
  const images = listing.images;

  const productImagesOrder = listing?.attributes?.publicData?.productImagesOrder || [];
  const orderedImages = [...images].sort((a, b) => {
    const aIndex = productImagesOrder.indexOf(a.id?.uuid);
    const bIndex = productImagesOrder.indexOf(b.id?.uuid);
    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  const aditionalGalleryOptions = {
    thumbnailPosition: 'left',
  }

  // const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const imageVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <div className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={orderedImages}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        aspectWidth={3}
        aspectHeight={4}
        thumbnailClassName={css.thumb}
        aditionalGalleryOptions={aditionalGalleryOptions}
        rootClassName={css.galleryRoot}
      />
    </div>
  );
};

export default SectionGallery;
